import React, { useState, useEffect } from "react";


import { useNavigate } from "react-router";
import "bootstrap/dist/js/bootstrap.js";
import { auth, logInWithEmailAndPassword } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Loading from "./loading";


export default function Login() {

const [email, setEmail] = useState("william.c.seil@gmail.com");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      <Loading />
      return;
    }
    if (user) navigate("/home");
  }, [user, loading]);
   

    return (
        
            
            <div class="row mt-5">
                   
                    <div class="col mt-5">
                        <div class="text-center">
                            <ul class="list-inline mt-3">
                                <h5>Secret Password</h5>
                                <li class="list-inline-item">
                                    <input 
                                        type="password" 
                                        class="form-control" 
                                        id="password" 
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)} 
                                    />
                                </li>
                                <li class="list-inline-item">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => logInWithEmailAndPassword(email, password)}>
                                        Log In
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                   
                    
                
            </div>
        
    )
}









