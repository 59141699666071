import { logout } from "./firebase";
import { useLocation } from "react-router-dom";


export default function Footer() {
    const location = useLocation();
    if (location.pathname == "/") {
        return null
    } else {
        return (
            <div class="row">
                <div class="col-12">
                   
                    <button type="button" className="btn btn-light float-end mt-3" onClick={logout}>
                        Logout
                    </button>
                </div>
                
            </div>
        )
        
    }
    

}