import React from "react";
import "../styles/styles.css";
import { useLocation, NavLink } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';

export default function Navbar() {
    const location = useLocation();
    if (location.pathname == "/home") {
        return (
            <Row>
                <nav className="navbar navbar-expand-lg navbar-light pt-5 ">
                    <Col class="text-center">
                        <NavLink className="navbar-brand " to="/">
                            <h1 class="display-3 m-3">Juni's Place</h1>
                        </NavLink>
                    </Col>
                
                </nav>
          </Row>
        );
        
    } else {
        return (
   
            <Row>
              <nav className="navbar navbar-expand-lg navbar-light">
                 <Col lg="10" sm="12">
                     <NavLink className="navbar-brand " to="/">
                         <h1 class="display-3 m-3">Juni's Place</h1>
                     </NavLink>
                </Col>
                <Col>
                     <ul className="navbar-nav ml-auto">
                         <li className="nav-item">
                             <ul class="nav flex-column">
                             <li class="nav-item">
                                     <NavLink className="nav-link" to="/reservations">
                                         <button type="button" class="btn btn-primary btn-sm w-100">Manage Reservations</button>
                                     </NavLink>
                                 </li>
                                 <li class="nav-item">
                                     <NavLink className="nav-link" to="/calendar">
                                         <button type="button" class="btn btn-info btn-sm w-100">View Calendar</button>
                                     </NavLink>
                                 </li>
                                 <li class="nav-item">
                                     <NavLink className="nav-link" to="/create">
                                         <button type="button" class="btn btn-light btn-sm w-100">Create Reservation</button>
                                     </NavLink>
                                 </li>
                             </ul>
                         </li>
                     </ul>
                </Col>
              </nav>
            </Row>
          );
    }

}