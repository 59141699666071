import Starfish from "../img/starfish-24.png";
import Seahorse from "../img/seahorse-24.png";
import "bootstrap/dist/js/bootstrap.js";
import { Link } from "react-router-dom";

function convertToMonth(monthNumber) {
    const eventMonth = new Date;
    eventMonth.setMonth(monthNumber - 1);
    return eventMonth.toLocaleString('en-US', {
        month: 'long',
      });
}


export default function EventModal(props) {
    
    const record = props.records.find(element => element._id === props.id)
    return(
      <div>
        <div className="viewRecord m-0" data-bs-toggle="modal" data-bs-target={'#staticBackdrop'+props.id}>
         {props.whichRoom === "room2" ? <div><img src={Starfish}></img> {props.records.find(element => element._id === props.id).eventName} </div> : <div><img src={Seahorse}></img> {props.records.find(element => element._id === props.id).eventName} </div> }
        </div>
        <div className="modal fade" id={"staticBackdrop"+props.id}
         data-bs-backdrop="static" 
         data-bs-keyboard="false" 
         tabIndex="-1" 
         aria-labelledby="staticBackdropLabel"
         aria-hidden="true">
         <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-primary" id="staticBackdropLabel">{record.eventName}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
               
                <ul class="list-group-flush">
                    <li class="list-group-item"><h5>Start</h5><span class="modalUL">{convertToMonth(record.startDate.split("-")[1]) + " " + record.startDate.split("-")[2] + ", " + record.startDate.split("-")[0]}</span></li>
                    <li class="list-group-item"><h5>End</h5><span class="modalUL">{convertToMonth(record.endDate.split("-")[1]) + " " + record.endDate.split("-")[2] + ", " + record.endDate.split("-")[0]}</span></li>
                    <li class="list-group-item"><h5>Room</h5><span class="modalUL">{record.rooms}</span></li>
                    <li class="list-group-item"><h5>Guests</h5><span class="modalUL">{record.guests}</span></li>
                    <li class="list-group-item"><h5>Pets</h5><span class="modalUL">{record.pets}</span></li>
                    <li class="list-group-item"><h5>Description</h5><span class="modalUL">{record.description}</span></li>
                </ul>
                                
                 
                
                
              </div>
              <div className="modal-footer">
              <Link className="btn btn-primary" to={{pathname:`/edit/${record._id}`, state:{allRecords: props.records}}} data-bs-dismiss="modal">Edit</Link> |
    
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                
              </div>
            </div>
          </div>
        </div>
    </div>
    )
}