import React from "react";
import "../styles/spinner.css";

export default function LoadingSpinner() {
  return (
    <div className="spinner-container pt-3">
      <div className="loading-spinner">
      </div>
    </div>
  );
}
