import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "../axios";
import { auth, db, logout } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";

let duplicateStartCheck = false;
let duplicateEndCheck = false;
let dupeStart = ""
let dupeEnd = ""

export default function Create(props) {
  const [user, loading, error] = useAuthState(auth);
  
  useEffect(() => {
      if (loading) return;
      if (!user) return navigate("/");
      
    }, [user, loading]);
  
  let selectedDate = "";
  let selectedRoom = "";
  const location = useLocation();
  if (props.selectedDay !== "") {
    selectedDate = props.selectedDay;
  }
  if (props.whichRoom !== "") {
    selectedRoom = props.whichRoom;
  }

  

 const [form, setForm] = useState({
   eventName: "",
   startDate: selectedDate,
   endDate: "",
   rooms: selectedRoom,
   guests: "",
   pets: "",
   description: "",
   records: []
 });

function findNextDay(date) {
  let s = date;
  let d = new Date(s);
  d.setUTCDate(d.getUTCDate() + 1);
  console.log(d.toISOString().substr(0,10));
  return d.toISOString().substr(0,10)
}

 const navigate = useNavigate();
 const [records, setrecords] = useState([]);



  useEffect(() => {
    async function getrecords() {
      const response = await axios.get("/record");
  
      const records = await response.data;
      setrecords(records);
    }
  
    getrecords();
  
    return;
  }, [records.length]);

 
 // These methods will update the state properties.
 function updateForm(value) {
   return setForm((prev) => {
     return { ...prev, ...value };
   });
 }
 
 // This function will handle the submission.
 async function onSubmit(e) {
   e.preventDefault();
  if (form.description=="") {
    form.description="-"
  }
  if (form.guests=="") {
    form.guests="-"
  }
  if (form.pets=="") {
    form.pets="-"
  }
   // When a post request is sent to the create url, we'll add a new record to the database.
   const newEvent = { ...form };
   await axios.post('/record/add/', newEvent);
   
 
   setForm({ eventName: "", startDate: "", endDate: "", rooms: "", guests: "", pets: "", description: "" });
   
  
    navigate("/reservations");
   
   
 }
 

function cancel() {
  if (location.pathname == "/calendar") {
    navigate("/calendar");
   } else {
    navigate("/");
   }
}
let i = 0;


function checkIfReservedStart(form, formRoom) {
  duplicateStartCheck = false;
  for (i = 0; i < records.length; i++) {
    
      if (formRoom.includes(records[i].rooms)  && Date.parse(records[i].startDate) <= Date.parse(form) && Date.parse(records[i].endDate) >= Date.parse(form) ) {
        duplicateStartCheck = true;
        dupeStart = records[i]._id;
      } 
  }
}

function checkIfReservedEnd (form, formRoom) {
  duplicateEndCheck = false;
  for (i = 0; i < records.length; i++) {
      if (formRoom.includes(records[i].rooms)  && Date.parse(records[i].startDate) <= Date.parse(form) && Date.parse(records[i].endDate) >= Date.parse(form) ) {
        duplicateEndCheck = true;
        dupeEnd = records[i]._id;
      } 
  }
}

let startDateChecker = (e, formRoom) => {
  updateForm({ startDate: e.target.value })
  checkIfReservedStart(e.target.value, formRoom)
  
}

let setEndDatePlaceholder = () => {
  if (form.endDate == '') {
    updateForm({ endDate: findNextDay(form.startDate) })
  }
  
}


let endDateChecker = (e, formRoom) => {
  updateForm({ endDate: e.target.value })
  checkIfReservedEnd(e.target.value, formRoom)
}

let roomChecker = (e, formStart, formEnd) => {
  updateForm({ rooms: e.target.value })
  checkIfReservedStart(formStart, e.target.value, )
  checkIfReservedEnd(formEnd, e.target.value,)
}



function showError() {
  if (duplicateStartCheck) {
    return(
      <div class="alert alert-danger">This room is already reserved on this date by 

        {" " + records.find(element => element._id === dupeStart).eventName + ". Try a different room or a different date."}
     </div>
  )}
}

function showErrorEnd() {
  if (duplicateEndCheck) {
    return(
      <div class="alert alert-danger">This room is already reserved for a portion of your selected dates by

        {" " + records.find(element => element._id === dupeEnd).eventName + ". Try a different room or a different date."}
     </div>
  )}
}


 // This following section will display the form that takes the input from the user.
 return (
   <div class="pt-3">
    {location.pathname == "/calendar" ? null : <h3>Create New Reservation</h3>}
     <form onSubmit={onSubmit}>
       <div className="form-group">
         <label htmlFor="eventName">Event Name</label>
         <input
           type="text"
           className="form-control"
           id="eventName"
           value={form.eventName}
           onChange={(e) => updateForm({ eventName: e.target.value })} required
         />
       </div>
       
       
       <div className="form-group">
        <label htmlFor="rooms">Rooms</label>
         <div className="form-check ">
           <input
             className="form-check-input"
             type="radio"
             name="rooms"
             id="seahorse"
             value="Seahorse"
             checked={form.rooms === "Seahorse"}
             onChange={(e) => roomChecker(e, form.startDate, form.endDate)} required
           />
           <label htmlFor="seahorse" className="form-check-label">Seahorse</label>
         </div>
         <div className="form-check">
           <input
             className="form-check-input"
             type="radio"
             name="rooms"
             id="starfish"
             value="Starfish"
             checked={form.rooms === "Starfish"}
             onChange={(e) => roomChecker(e, form.startDate, form.endDate)}
           />
           <label htmlFor="starfish" className="form-check-label">Starfish</label>
         </div>
         <div className="form-check ">
           <input
             className="form-check-input"
             type="radio"
             name="rooms"
             id="seahorsestarfish"
             value="Seahorse and Starfish"
             checked={form.rooms === "Seahorse and Starfish"}
             onChange={(e) => roomChecker(e, form.startDate, form.endDate)}
           />
           <label htmlFor="seahorsestarfish" className="form-check-label">Seahorse and Starfish</label>
         </div>
       </div>
       




       <div className="form-group">
         <label htmlFor="rooms">Start</label>
         <input
           type="date"
           className="form-control"
           id="startDate"
           value={form.startDate}
           onChange={(e) => startDateChecker(e, form.rooms)} required
         />
         
       </div>
       <div>{showError()}</div>

       <div className="form-group">
         <label htmlFor="endDate">End</label>
         <input
           type="date"
           className="form-control"
           id="endDate"
           value={form.endDate}
           placeholder={() => setEndDatePlaceholder()}
           onChange={(e) => endDateChecker(e, form.rooms)}
          onFocus={() => setEndDatePlaceholder()}
           required
         />
       </div>
       <div>{showErrorEnd()}</div>






       <div className="form-group">
         <label htmlFor="guests">Guests</label>
         <input
           type="text"
           className="form-control"
           id="guests"
           value={form.guests}
           onChange={(e) => updateForm({ guests: e.target.value })}
         />
       </div>
       <div className="form-group">
         <label htmlFor="pets">Pets</label>
         <input
           type="text"
           className="form-control"
           id="pets"
           value={form.pets}
           onChange={(e) => updateForm({ pets: e.target.value })}
         />
       </div>
       <div className="form-group">
         <label htmlFor="description">Description</label>
         <textarea
           rows="3"
           className="form-control"
           id="description"
           value={form.description}
           onChange={(e) => updateForm({ description: e.target.value })}
         ></textarea>
       </div>
       <div className="form-group">
        {(duplicateStartCheck || duplicateEndCheck) ? <input type="submit" value="Create event" className="btn btn-primary" disabled/>: <input type="submit" value="Create event" data-bs-dismiss="modal" className="btn btn-primary"
       />
         
         
         }
         
         <button class="btn btn-secondary" data-bs-dismiss="modal" onClick={cancel}>Cancel</button>
       </div>
       
     </form>
   </div>
 );
}