import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import './styles/styles.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAfGa3xxy5SMzxBM5sBKcTDgPEwn7Ej0EY",
  authDomain: "juni-s-place-mern.firebaseapp.com",
  projectId: "juni-s-place-mern",
  storageBucket: "juni-s-place-mern.appspot.com",
  messagingSenderId: "550845914062",
  appId: "1:550845914062:web:0bf244727a99017722299f",
  measurementId: "G-8B56F9KFQT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);