import "../styles/styles.css";
import { useEffect, useState } from "react";
import Calendar, { CalendarDayHeader } from "./calendarDisplay.js";

const CalendarApp = () =>  {
  let d = new Date();
  let month = d.getMonth();
  const [yearAndMonth, setYearAndMonth] = useState([2023, month + 1]);
  return (
    <div>
      <Calendar
        yearAndMonth={yearAndMonth}
        onYearAndMonthChange={setYearAndMonth}
        renderDay={(calendarDayObject) => (
          <div>
            <CalendarDayHeader calendarDayObject={calendarDayObject} />
          </div>
        )}
      />
    </div>
  );
}

export default CalendarApp;