import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import 'react-tabs/style/react-tabs.css';
import axios from "../axios";
import LoadingSpinner from "./loading";
import { auth, db, logout } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";



const Record = (props) => (
 <tr>
   <td>{props.record.eventName}</td>
   <td>{props.record.startDate}</td>
   <td>{props.record.endDate}</td>
   <td>{props.record.rooms}</td>
   <td>{props.record.guests}</td>
   <td>{props.record.pets}</td>
   <td>{props.record.description}</td>
   <td>
     <Link className="btn btn-link" to={{pathname:`/edit/${props.record._id}`, state:{allRecords: props.records}}}>Edit</Link> |
     <button className="btn btn-link"
       onClick={() => {
         props.deleteRecord(props.record._id);
       }}
     >
       Delete
     </button>
   </td>
 </tr>
);
 
export default function RecordList() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
    
  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    
  }, [user, loading]);

 const [records, setRecords] = useState([]);
 const [isLoading, setIsLoading] = useState(true);
 // This method fetches the records from the database.
 useEffect(() => {
   async function getRecords() {
     const response = await axios.get("/record/");
     const records = await response.data;
     setRecords(records);
     setIsLoading(false);
     console.log(records);
   }
   getRecords();
   
   return;
 }, [records.length]);

 // This method will delete a record
 async function deleteRecord(id) {
  await axios.delete(id)
   const newRecords = records.filter((el) => el._id !== id);
   setRecords(newRecords);
 }

let futureRecords = records.filter(item => Date.parse(item.endDate) >= Date.parse(new Date().toISOString()) );
let futureRecordsSorted = futureRecords.sort((a, b) => Date.parse(a.startDate) - Date.parse(b.startDate))

function recordList() {
  return futureRecordsSorted.map((record) => {
    return (
      <Record
        records={records}
          record={record}
        deleteRecord={() => deleteRecord(record._id)}
        key={record._id}
      />
    );
  });}

 // This following section will display the table with the records of individuals.

 if (isLoading === true) {
  return (
    
    <LoadingSpinner />
  )
 } else {
  return (
  
    <div class="row align-items-center pt-3 mb-5 table-responsive-lg">
      <div class="col-10"><h3>Reservations</h3></div>
      <div class="col"><Link className="btn btn-link" to={`/history/`}>View History</Link></div>
      <table className="table table-striped" style={{ marginTop: 20 }} >
        <thead>
          <tr>
            <th>Event Name</th>
            <th>Start</th>
            <th>End</th>
            <th>Rooms</th>
            <th>Guests</th>
            <th>Pets</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>{recordList()}</tbody>
      </table>
    </div>
    
  );
 }
 
}