import Form from 'react-bootstrap/Form';

export default function Rules() {
    return(
        <div>
            <h2>House Rules</h2>
            <p>Welcome to Juni's Place!</p>
            <hr />
            <h5>Internet Log In and Password:</h5>
            <ul className="mb-4">
                <li>Network: SpectrumSetup-00</li>
                <li>Password: vastball910</li>
            </ul>

            <h5>Things to Bring</h5>
            <ul className="mb-4">
                <li>Bath towels</li>
                <li>Bed sheets (full for Starfish, queen for Seahorse)</li>
                <li>Toiletries</li>
                <li>Personal items</li>
                <li>Phone charger </li>
                <li>Kitchen towels and hand towels and a bath mat for the bathroom will be provided. You can leave things such as toiletries, shoes, clothes, etc. in your closet the Seahorse Room.</li>
            </ul>
            <h5>Departure Checklist</h5>
            <Form className="m-2 mb-4">
                <Form.Check label="Wipe down counters in kitchen and bathroom" />
                <Form.Check label="Remove any perishable food from the refrigerator -unless you plan to return before they expire- and clean the shelves if they need attention" />
                <Form.Check label="Wipe the toilet seat with disinfectant wipes" />
                <Form.Check label="Check the Bathroom Cleaning calendar to see when it was last done. Please clean it if it's been 2 weeks or close to it" />
                <Form.Check label="Take out the garbage" />
                <Form.Check label="Wash and put away dishes. Note: the dishwasher takes at least 3 hours to run if you use the dry cycle" />
                <Form.Check label="Sweep/mop/vacuum if necessary" />
                <Form.Check label="You can leave hand towels and kitchen towels hanging up to dry and the next person can toss them in a dirty clothes basket located in the Seahorse bedroom" />
                <Form.Check label="Turn the heat and fireplace off before you leave. In the winter time we may have to keep it at 60 degrees so the kitchen sink pipes don't freeze" />
                <Form.Check label="Close blinds in bedrooms and the kitchen" />
                <Form.Check label="CLOSE and LOCK all windows and doors" />
                
            </Form>
            <h5>General Rules</h5>
            <ol className="mb-4">
                <li>Rinse sandy items outside with the hose</li>
                <li>The washer and dryer are available to use between the hours of 9am and 9pm. Wash dirty towels if there is a big enough load.</li>
                <li>There is no recycling, so please take it with you!</li>
                <li>Replace items as they run out (e.g. toilet paper, paper towels, etc). Extra supplies will be kept in the middle closet in the Seahorse room. Cleaning supplies are in the Utility closet by the front door. </li>
                <li>Leave washing machine cracked open after use to let dry out.</li>
            </ol>
            
            <h5>Parking</h5>
            <p>We have one parking space in front of Unit #19. Second vehicles are to be parked in the spaces for Guest Parking adjacent to Building 1 and 6. You MUST email Ronda Lovley at <a href="mailto: rlovley@iwmhoa.com">rlovley@iwmhoa.com</a> at Palisades Condominiums if you plan to leave the car overnight or it might be towed.</p>
            <p>Become familiar with the Rules and Regulations listed on the Palisades Condominium website: <a href="https://palisadescondominium.net/">https://palisadescondominium.net/</a></p>
            
            
            



        </div>

    )
}