import Starfish from "../img/starfish-24.png";
import Seahorse from "../img/seahorse-24.png";
import "bootstrap/dist/js/bootstrap.js";
import { Link } from "react-router-dom";
import Create from "./create";

function convertToMonth(monthNumber) {
    const eventMonth = new Date;
    eventMonth.setMonth(monthNumber - 1);
    return eventMonth.toLocaleString('en-US', {
        month: 'long',
      });
}


export default function CreateModal(props) {
    let whichRoom = ""
    if (props.whichRoom == "room1") {
        whichRoom = "Seahorse"
    } else {
        whichRoom = "Starfish"
    }
    
    return(
        <div>
            <div className="viewRecord m-0" data-bs-toggle="modal" data-bs-target={'#staticBackdrop'+props.day}>
                {props.whichRoom === "room2" ? <div><img src={Starfish}></img> Available </div> : <div><img src={Seahorse}></img> Available </div> }
            </div>
            <div className="modal fade" id={"staticBackdrop"+props.day}
                data-bs-backdrop="static" 
                data-bs-keyboard="false" 
                tabIndex="-1" 
                role="dialog"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-primary" id="staticBackdropLabel">Create Reservation</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <Create selectedDay={props.day} whichRoom={whichRoom}/>
                            
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}