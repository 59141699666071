import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { auth, db, logout } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

export default function Home() {
    
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    
    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        
      }, [user, loading]);
    
      return(
        <div class="row pt-5 home">
            <div class="col"></div>
            <div class="col-6">
                <div class="row mb-5">
                    <Link className="btn btn-primary btn-lg" to={`/reservations`}><br />Manage Reservations<br /><br /></Link>
                </div>
                <div class="row mb-5">
                    <Link className="btn btn-info btn-lg" to={`/calendar`}><br />View Calendar<br /><br /></Link>
                </div>
                <div class="row mb-5">
                    <Link className="btn btn-light btn-lg" to={`/create`}><br />Create a New Reservation<br /><br /></Link>
                </div>
                <div class="row mb-3">
                    <Link className="btn btn-secondary btn-lg" to={`/rules`}><br />House Rules<br /><br /></Link>
                </div>
            </div>
            <div class="col"></div>
        
           
        </div>
    )
    
}