import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  daysOfWeek,
  createDaysForCurrentMonth,
  createDaysForNextMonth,
  createDaysForPreviousMonth,
  isWeekendDay,
  getMonthDropdownOptions,
  getYearDropdownOptions,
  currentDate
} from "./helpers";
import axios from "../axios";
import { isCurrentDay } from "./helpers";
import Starfish from "../img/starfish-24.png";
import Seahorse from "../img/seahorse-24.png";
import EventModal from "./eventModal";
import CreateModal from "./createModal";
import LoadingSpinner from "./loading";
import { auth, db, logout } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

Calendar.propTypes = {
  className: PropTypes.string,
  yearAndMonth: PropTypes.arrayOf(PropTypes.number).isRequired, // e.g. [2021, 6] for June 2021
  onYearAndMonthChange: PropTypes.func.isRequired,
  renderDay: PropTypes.func
};
export default function Calendar({
  


  className = "",
  yearAndMonth = [currentDate.currentYear, currentDate.currentMonth],
  onYearAndMonthChange,
  renderDay = () => null
}) {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
    
  

  useEffect(() => {
      if (loading) return;
      if (!user) return navigate("/");
      
    }, [user, loading]);
  const [year, month] = yearAndMonth;

  let currentMonthDays = createDaysForCurrentMonth(year, month);
  const [isLoading, setIsLoading] = useState(true);
  const [records, setrecords] = useState([]);
    
  // This method fetches the records from the database.
  useEffect(() => {
    
    async function getrecords() {
      
      const response = await axios.get("/record/");
      const records = await response.data;
      setrecords(records);
      setIsLoading(false);
    }
  
    getrecords();
    
    return;
  }, [records.length]);

  
  
 

  let previousMonthDays = createDaysForPreviousMonth(
    year,
    month,
    currentMonthDays
  );
  let nextMonthDays = createDaysForNextMonth(year, month, currentMonthDays);
  let calendarGridDayObjects = [
    ...previousMonthDays,
    ...currentMonthDays,
    ...nextMonthDays
  ];

  const handleMonthNavBackButtonClick = () => {
    let nextYear = year;
    let nextMonth = month - 1;
    if (nextMonth === 0) {
      nextMonth = 12;
      nextYear = year - 1;
    }
    onYearAndMonthChange([nextYear, nextMonth]);
  };

  const handleMonthNavForwardButtonClick = () => {
    let nextYear = year;
    let nextMonth = month + 1;
    if (nextMonth === 13) {
      nextMonth = 1;
      nextYear = year + 1;
    }
    onYearAndMonthChange([nextYear, nextMonth]);
  };

  const handleMonthSelect = (evt) => {
    let nextYear = year;
    let nextMonth = parseInt(evt.target.value, 10);
    onYearAndMonthChange([nextYear, nextMonth]);
  };

  const handleYearSelect = (evt) => {
    let nextMonth = month;
    let nextYear = parseInt(evt.target.value, 10);
    onYearAndMonthChange([nextYear, nextMonth]);
  };

  let i = 0;
  let x = 0;


function updateEventData() {
    for (i = 0; i < records.length; i++) {
        for (x = 0; x < currentMonthDays.length; x++) {
            
            if (Date.parse(records[i].startDate) <= Date.parse(currentMonthDays[x].dateString) && Date.parse(records[i].endDate) >= Date.parse(currentMonthDays[x].dateString)){
                  currentMonthDays[x].eventData = records[i]._id;
                  if (records[i].rooms.includes("Seahorse and Starfish")) {
                    currentMonthDays[x].eventDataRoom1 = records[i]._id;
                    currentMonthDays[x].eventDataRoom2 = records[i]._id;
                    
                  } else if (records[i].rooms.includes("Seahorse")) {
                    currentMonthDays[x].eventDataRoom1 = records[i]._id;
                   
                   
                  } else if (records[i].rooms.includes("Starfish")) {
                    currentMonthDays[x].eventDataRoom2 = records[i]._id;
                    
                  }
                  
              }
          }
    }
}

updateEventData();
 if (isLoading === true) {
  console.log("loading")
  return (
    <LoadingSpinner />
  )
 } else {
  console.log("loading complete")
  return (
    <div className="calendar-root mt-3">
      <div className="navigation-header">
        <div className="month-nav-arrow-buttons">
          <button onClick={handleMonthNavBackButtonClick}> prev </button>
          <button onClick={handleMonthNavForwardButtonClick}>next</button>
        </div>
        <select
          className="month-select"
          value={month}
          onChange={handleMonthSelect}
        >
          {getMonthDropdownOptions().map(({ label, value }) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
        <select
          className="year-select"
          value={year}
          onChange={handleYearSelect}
        >
          {getYearDropdownOptions(year).map(({ label, value }) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div className="days-of-week">
        {daysOfWeek.map((day, index) => (
          <div
            key={day}
            className={classNames("day-of-week-header-cell", {
              "weekend-day": [6, 0].includes(index)
            })}
          >
            {day}
          </div>
        ))}
      </div>
      <div>{updateEventData()}</div>
      <div className="days-grid">
        {calendarGridDayObjects.map((day) => (
          <div
            key={day.dateString}
            className={classNames("day-grid-item-container", {
              "weekend-day": isWeekendDay(day.dateString),
              "current-month": day.isCurrentMonth, "current-day": isCurrentDay(day)
            })}
          >
            <div className="day-content-wrapper">{renderDay(day)}</div>
             <div>
                
                {(day.isCurrentMonth && day.eventDataRoom1.length > 2) ? <div class="p-2 bg-primary text-white room1title"> <EventModal id={day.eventDataRoom1} records={records} whichRoom="room1"/></div>: (day.isCurrentMonth) ? <div class="p-2 bg-secondary text-white room1available"><CreateModal day={day.dateString} records={records} whichRoom="room1"/></div>: "" }
                {(day.isCurrentMonth && day.eventDataRoom2.length > 2) ? <div class="p-2 bg-info text-white room2title"><EventModal id={day.eventDataRoom2} records={records} whichRoom="room2"/></div>: (day.isCurrentMonth) ? <div class="p-2 bg-secondary text-white room2available"><CreateModal day={day.dateString} records={records} whichRoom="room2"/></div>: ""}
                
                 
                
             </div>
             
          </div>
        ))}
      </div>
      
          

            
    </div>
    
  );
}}

CalendarDayHeader.propTypes = {
  calendarDayObject: PropTypes.object.isRequired
};
export function CalendarDayHeader({ calendarDayObject }) {
  return (
    <div className="day-grid-item-header">{calendarDayObject.dayOfMonth}</div>
  );
}
