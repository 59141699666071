import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import axios from "../axios";
import LoadingSpinner from "./loading";
import { auth, db, logout } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";

let duplicateStartCheck = false;
let duplicateEndCheck = false;
let dupeStart = ""
let dupeEnd = ""
let i = 0;

export default function Edit(props) {
  const [user, loading, error] = useAuthState(auth);
    
  useEffect(() => {
      if (loading) return;
      if (!user) return navigate("/");
      
  }, [user, loading]);
 const [form, setForm] = useState({
   eventName: "",
   startDate: "",
   endDate: "",
   rooms: "",
   guests: "",
   pets: "",
   description: ""
 });
 const params = useParams();
 const navigate = useNavigate();
 const [records, setrecords] = useState([]);
 const [isLoading, setIsLoading] = useState(true);

 useEffect(() => {
  
   async function fetchData() {
     const id = params.id.toString();
     const response = await axios.get("/record");
 
    


     const records = await response.data;
     const record = records.find(element => element._id == params.id.toString())
     
     if (!record) {
       window.alert(`Record with id ${id} not found`);
       navigate("/reservations");
       return;
     }
     setrecords(records);
     setForm(record);
     setIsLoading(false);
   }
 
   fetchData();
 
   return;
 }, [params.id, navigate]);
 
 // These methods will update the state properties.
 function updateForm(value) {
   return setForm((prev) => {
     return { ...prev, ...value };
   });
 }
 
 async function onSubmit(e) {
   e.preventDefault();

   if (form.description=="") {
    form.description="-"
    }
    if (form.guests=="") {
      form.guests="-"
    }
    if (form.pets=="") {
      form.pets="-"
    }
   const editedEvent= {
     eventName: form.eventName,
     startDate: form.startDate,
     endDate: form.endDate,
     rooms: form.rooms,
     guests: form.guests,
     pets: form.pets,
     description: form.description,
   };

   navigate("/reservations");
 
   // This will send a post request to update the data in the database.
   await axios.post('/update/' + params.id, editedEvent);
   
   
   
 }

 function cancel() {
    navigate("/");
}

let otherRecords = records;
let editedEventIndex = otherRecords.findIndex(item => {
  return item._id == params.id
});



if (editedEventIndex > -1) {
    otherRecords.splice(editedEventIndex, 1)
}


function checkIfReservedStart(form, formRoom) {
  duplicateStartCheck = false;
  for (i = 0; i < otherRecords.length; i++) {
      if (formRoom.includes(otherRecords[i].rooms)  && Date.parse(otherRecords[i].startDate) <= Date.parse(form) && Date.parse(otherRecords[i].endDate) >= Date.parse(form) ) {
        duplicateStartCheck = true;
        dupeStart = otherRecords[i]._id;
      } 
  }
}

function checkIfReservedEnd (form, formRoom) {
  duplicateEndCheck = false;
  for (i = 0; i < otherRecords.length; i++) {
      if (formRoom.includes(otherRecords[i].rooms)  && Date.parse(otherRecords[i].startDate) <= Date.parse(form) && Date.parse(otherRecords[i].endDate) >= Date.parse(form) ) {
        duplicateEndCheck = true;
        dupeEnd = otherRecords[i]._id;
      } 
  }
}

let startDateChecker = (e, formRoom) => {
  updateForm({ startDate: e.target.value })
  checkIfReservedStart(e.target.value, formRoom)
}

let endDateChecker = (e, formRoom) => {
  updateForm({ endDate: e.target.value })
  checkIfReservedEnd(e.target.value, formRoom)
}

let roomChecker = (e, formStart, formEnd) => {
  updateForm({ rooms: e.target.value })
  checkIfReservedStart(formStart, e.target.value, )
  checkIfReservedEnd(formEnd, e.target.value,)
}

function showError() {
  if (duplicateStartCheck) {
    return(
      <div class="alert alert-danger">This room is already reserved on this date by 
        {" " + otherRecords.find(element => element._id === dupeStart).eventName + ". Try a different room or a different date."}
     </div>
  )}
}

function showErrorEnd() {
  if (duplicateEndCheck) {
    return(
      <div class="alert alert-danger">This room is already reserved for a portion of your selected dates by
        {" " + otherRecords.find(element => element._id === dupeEnd).eventName + ". Try a different room or a different date."}
     </div>
  )}
}





 
 // This following section will display the form that takes input from the user to update the data.

 if (isLoading === true) {
  return (
    <LoadingSpinner />
  )
 } else {
 
  return (
    
    <div class="pt-3">
    <h3>Edit Reservation</h3>
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label htmlFor="eventName">Event Name</label>
        <input
          type="text"
          className="form-control"
          id="eventName"
          value={form.eventName}
          onChange={(e) => updateForm({ eventName: e.target.value })} required
        />
      </div>
      
      
      <div className="form-group">
       <label htmlFor="rooms">Rooms</label>
        <div className="form-check ">
          <input
            className="form-check-input"
            type="radio"
            name="rooms"
            id="seahorse"
            value="Seahorse"
            checked={form.rooms === "Seahorse"}
            onChange={(e) => roomChecker(e, form.startDate, form.endDate)} required
          />
          <label htmlFor="seahorse" className="form-check-label">Seahorse</label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="rooms"
            id="starfish"
            value="Starfish"
            checked={form.rooms === "Starfish"}
            onChange={(e) => roomChecker(e, form.startDate, form.endDate)}
          />
          <label htmlFor="starfish" className="form-check-label">Starfish</label>
        </div>
        <div className="form-check ">
          <input
            className="form-check-input"
            type="radio"
            name="rooms"
            id="seahorsestarfish"
            value="Seahorse and Starfish"
            checked={form.rooms === "Seahorse and Starfish"}
            onChange={(e) => roomChecker(e, form.startDate, form.endDate)}
          />
          <label htmlFor="seahorsestarfish" className="form-check-label">Seahorse and Starfish</label>
        </div>
      </div>
      
  
  
  
  
      <div className="form-group">
        <label htmlFor="rooms">Start</label>
        <input
          type="date"
          className="form-control"
          id="startDate"
          value={form.startDate}
          onChange={(e) => startDateChecker(e, form.rooms)} required
        />
        
      </div>
      <div>{showError()}</div>
  
      <div className="form-group">
        <label htmlFor="endDate">End</label>
        <input
          type="date"
          className="form-control"
          id="endDate"
          value={form.endDate}
          onChange={(e) => endDateChecker(e, form.rooms)} required
        />
      </div>
      <div>{showErrorEnd()}</div>
  
  
  
  
  
  
      <div className="form-group">
        <label htmlFor="guests">Guests</label>
        <input
          type="text"
          className="form-control"
          id="guests"
          value={form.guests}
          onChange={(e) => updateForm({ guests: e.target.value })}
        />
      </div>
      <div className="form-group">
        <label htmlFor="pets">Pets</label>
        <input
          type="text"
          className="form-control"
          id="pets"
          value={form.pets}
          onChange={(e) => updateForm({ pets: e.target.value })}
        />
      </div>
      <div className="form-group">
        <label htmlFor="description">Description</label>
        <textarea
          rows="3"
          className="form-control"
          id="description"
          value={form.description}
          onChange={(e) => updateForm({ description: e.target.value })}
        ></textarea>
      </div>
      <div className="form-group">
        {(duplicateStartCheck || duplicateEndCheck) ? <input type="submit" value="Update event" className="btn btn-primary" disabled/>: <input type="submit" value="Update event" className="btn btn-primary" />}
            
        <button class="btn btn-secondary" onClick={() => navigate("/reservations")}>Cancel</button>
      </div>
      
    </form>
  </div>
    
  );
}}