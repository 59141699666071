import React from "react";
 
// We use Route in order to define the different routes of our application
import { Route, Routes } from "react-router-dom";
 
// We import all the components we need in our app
import Navbar from "./components/navbar";
import RecordList from "./components/recordList";
import RecordListHistory from "./components/recordHistory";
import Edit from "./components/edit";
import Create from "./components/create";
import CalendarApp from "./components/calendar";
import Home from "./components/home";
import Rules from './components/rules';
import Login from "./components/login";
import Footer from "./components/footer";

const App = () => {
 return (
   <div class="container">
     <Navbar />
     <Routes>
        <Route exact path="/home" element={<Home />} />
       <Route path="/reservations" element={<RecordList />} />
       <Route path="/edit/:id" element={<Edit />} />
       <Route path="/create" element={<Create />} />
       <Route path="/calendar" element={<CalendarApp />} />
       <Route path="/history" element={<RecordListHistory />} />
       <Route path="/rules" element={<Rules />} />
       <Route path="/" element={<Login />} />
     </Routes>
     <Footer />
   </div>
 );
};
 
export default App;